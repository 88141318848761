import { Splide } from "@splidejs/splide";

// JavaScript code for the custom web component
class FacetSlider extends HTMLElement {
    constructor() {
      super();
    }

    connectedCallback() {
     new Splide(this, {
        perMove    : 1,
        pagination : false,
        arrows     : false,
        mediaQuery : 'min',
        gap        : '0.5rem',
        fixedWidth : '2rem',
        fixedHeight : '2rem',
        padding    : {
          left: '1rem',
          right: '1rem' },
        breakpoints: {
          1024: {
            destroy: true
          },
        }
      }).mount();
    }

}

  customElements.define('color-facet-slider', FacetSlider);
